// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import("bootstrap");
import("../stylesheets/application");

require("@rails/actiontext");

// Stimulus
import "controllers";

// Selectize
import { selectize } from '../components/selectize';
selectize();

document.addEventListener("turbolinks:load", () => {
  // $('[data-toggle="tooltip"]').tooltip();
  // $('[data-toggle="popover"]').popover();

  changeOpacity();
  setFormsCallbacks();
})

// manage logo opacity during scrolling
function changeOpacity() {
  $(function () {
    // this limit the effect just to the homepage
    if (
      window.location.pathname.split("/")[1] == "" ||
      window.location.pathname.split("/")[1] == "welcome"
    ) {
      $(window).on( 'scroll', (function (e) {
        var s = $(window).scrollTop(),
          opacityVal = s / 1000;

        $("#navbar-left-logo").css("opacity", opacityVal);
        $("#headertron").css("opacity", 1 - opacityVal);
      }));
    }
  });
}

function setFormsCallbacks() {
  $(function () {
    $("#newsletter_subscribe_form").on("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("newsletter_submit_div").innerHTML = data;
    });
    $("#newsletter_subscribe_form").on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("newsletter_submit_div").innerHTML = data;
    });
    $("#general_contact_form").on("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("general-contact_submit_div").innerHTML = data;
    });
    $("#general_contact_form").on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("general-contact_submit_div").innerHTML = data;
    });
    $("#book_consult_call_form").on("ajax:success", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("book-consult_call_submit_div").innerHTML = data;
    });
    $("#book_consult_call_form").on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail;
      document.getElementById("book-consult_call_submit_div").innerHTML = data;
    });
  });
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

changeOpacity();
setFormsCallbacks();

$(function () {
  (function (window) {
    var navbar = document.getElementById("navbar");
    var page_container = document.getElementById("page-container");

    var Storage = function () {
      function browserHasSupport() {
        var testItem = "localStorageTest";
        if (window.localStorage) {
          try {
            window.localStorage.setItem(testItem, testItem);
            window.localStorage.removeItem(testItem);
            return true;
          } catch (e) {
            return false;
          }
        }
      }

      function deleteItem(item) {
        return window.localStorage.removeItem(item);
      }

      function getItem(item) {
        return window.localStorage.getItem(item);
      }

      function setItem(item, contents) {
        return window.localStorage.setItem(item, contents);
      }

      return {
        delete: deleteItem,
        enabled: browserHasSupport,
        get: getItem,
        set: setItem,
      };
    };

    window.Storage = new Storage();
  })(window);

  (function (window, Storage) {
    var storageEnabled = window.Storage.enabled();

    var dismissibleItem = function (el, type, id, value, expiry) {
      var hasExpiry = id && expiry ? true : false,
        html =
          "<span>" +
          value +
          ' <button type="button" class="close">X</button></span>';

      if (hasExpiry && storageEnabled) {
        var timestamp = window.Storage.get(id);
        if (timestamp) {
          if (expiry === "forever") {
            el.remove();
            return;
          } else {
            var now = new Date(),
              diffInHours = Math.floor(
                (now.getTime() - parseInt(timestamp)) / (1000 * 60 * 60)
              );
            if (diffInHours >= expiry) {
              window.Storage.delete(id);
            } else {
              el.remove();
              return;
            }
          }
        }
      }

      el.classList.add("pagewide", "p-3", "d-lg-block", "fixed-top", "dismissible", "dismissible-" + type);

      el.removeAttribute("data-component");
      el.removeAttribute("data-expiry");
      el.removeAttribute("data-id");
      el.removeAttribute("data-type");
      el.removeAttribute("data-value");

      el.innerHTML = html;

      // console.log('navbar: ' + window.navbar.offsetTop);
      // console.log('el: ' + el.offsetHeight);
      window.navbar.setAttribute(
        "style",
        // "top: " + (window.navbar.offsetTop + el.offsetHeight) + "px;"
        "top: " + 57 + "px;"
      );
      // console.log("navbar: " + window.navbar.offsetTop);
      // console.log("el: " + el.offsetHeight);

      // if (window.page_container) {
      //   // console.log("page_container: " + window.page_container.marginTop);
      //   // console.log("el: " + el.offsetHeight);
      //   window.page_container.setAttribute(
      //     "style",
      //     "margin-top: " +
      //       (window.page_container.marginTop + el.offsetHeight) +
      //       "px;"
      //   );
      //   // console.log("page_container: " + window.page_container.marginTop);
      //   // console.log("el: " + el.offsetHeight);
      // } else {
      //   // console.log("page_container is not defined");
      // }

      function dismissBanner(event) {
        var new_top;
        var difference_margin_top;
        var height = el.offsetHeight,
          opacity = 1,
          timeout = null;

        if (hasExpiry && storageEnabled) {
          window.Storage.set(id, new Date().getTime());
        }

        function reduceHeight() {
          height -= 2;
          el.setAttribute(
            "style",
            "height: " + height + "px; opacity: " + opacity
          );
          if (height <= 0) {
            window.clearInterval(timeout);
            timeout = null;
            el.remove();
            new_top = 0;
            difference_margin_top = height + 2;
          } else {
            new_top = height;
            difference_margin_top = 2
          }

          window.navbar.setAttribute("style", "top: " + new_top + "px;");
          if (window.page_container) {
            window.page_container.setAttribute(
              "style",
              "top: " +
                (window.page_container.marginTop - difference_margin_top) +
                "px;"
            );
          }
        }

        function fade() {
          opacity -= 0.1;
          el.setAttribute("style", "opacity: " + opacity);
          if (opacity <= 0) {
            window.clearInterval(timeout);
            timeout = window.setInterval(reduceHeight, 1);
          }
        }
        timeout = window.setInterval(fade, 25);
      }

      el.querySelector(".close").addEventListener("click", dismissBanner);
    };

    var dismissibles = Array.prototype.slice.call(
      document.querySelectorAll('[data-component="dismissible-item"]')
    );
    if (dismissibles.length) {
      for (var i = 0; i < dismissibles.length; i++) {
        var expiry = dismissibles[i].getAttribute("data-expiry"),
          id = dismissibles[i].getAttribute("data-id"),
          type = dismissibles[i].getAttribute("data-type"),
          value = dismissibles[i].getAttribute("data-value");
        new dismissibleItem(dismissibles[i], type, id, value, expiry);

      }
    }
  })(window, Storage);

  $(function () {
    $("select").selectize({
      plugins: ["remove_button", "drag_drop"],
    });
  });

  $(function () {
    $("#page_keywords").selectize({
      delimiter: ",",
      persist: false,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  });
});