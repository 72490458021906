require('jquery-ui');
require('jquery-ui/themes/base/all.css');
require('jquery-ui/ui/widgets/sortable');
import 'selectize/dist/js/selectize.min.js';

const selectize = () => {
  $('.select-beast').selectize({
      // create: true, (from example on selectize page)
      sortField: 'text'
  });

};

export { selectize };