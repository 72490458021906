import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Table from "@ckeditor/ckeditor5-table/src/table";

import UploadAdapter from "../packs/upload_adapter";

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader, editor);
  };
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // console.log("Hello, Stimulus!", this.element);

    // console.log("getting_input_id");
    var input_id = this.element.getAttribute("input");
    // console.log("input_id", input_id);

    // console.log("getting_input_element");
    var input_element = document.getElementById(input_id);
    // console.log("input_element", input_element);

    ClassicEditor.create(this.element, {
      plugins: [
        Essentials,
        CloudServicesPlugin,
        Paragraph,
        Bold,
        Italic,
        Image,
        ImageToolbar,
        ImageCaption,
        ImageStyle,
        ImageResize,
        ImageUpload,
        Alignment,
        Strikethrough,
        Underline,
        Subscript,
        Superscript,
        Code,
        Highlight,
        HorizontalLine,
        RemoveFormat,
        Autoformat,
        BlockQuote,
        EasyImage,
        Heading,
        ImageUpload,
        Link,
        List,
        Indent,
        MediaEmbed,
        Table,
        FileRepository,
      ],
      image: {
        toolbar: [
          "imageTextAlternative",
          "|",
          "imageStyle:full",
          // "imageStyle:side",
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
        ],
        styles: [
          // This option is equal to a situation where no style is applied.
          "full",
          // This represents an image aligned to left.
          "alignLeft",
          // This represents an image aligned to right.
          "alignRight",
          // "side",
          // This represents an image aligned to center.
          "alignCenter",
        ],
      },
      toolbar: {
        items: [
          "heading",
          "removeformat",
          "horizontalline",
          "|",
          "alignment",
          "bold",
          "italic",
          "underline",
          "|",
          "strikethrough",
          "subscript",
          "superscript",
          "|",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "highlight",
          "indent",
          "outdent",
          "|",
          "imageUpload",
          "mediaEmbed",
          "|",
          "code",
          "blockQuote",
          "insertTable",
          "|",
          "undo",
          "redo",
        ],
      },
      extraPlugins: [CustomUploadAdapterPlugin],
      customUpload: {
        directUploadUrl: this.element.dataset.directUploadUrl,
        blobUrlTemplate: this.element.dataset.blobUrlTemplate,
      },
    }).then((editor) => {
      // console.log("Editor was initialized", editor);

      // set existing value to CKEditor
      // console.log("setting_value");
      editor.setData(input_element.value);
      // console.log("data set");

      // update form data (TODO: ideally we should hook this to the form button and avoid re-set at each change)
      editor.model.document.on("change:data", () => {
        // console.log("The data has changed!");
        input_element.value = editor.getData();
      });
    });
    // .catch((error) => {
    //   console.error('Editor failed during initialization', error.stack);
    // });
  }
}
